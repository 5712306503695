


























import { Vue, Component } from "vue-property-decorator";
@Component({})
export default class ProjectTeam extends Vue {
    pid: any = "";
    project: object = {};
    newProject: any = {};

    vertical_list: object = [
        "technology_introduction",
        "technology_develop",
        "technology_sustainability",
        "technology_advantage",
        "technology_market",
        "product_description",
        "cooperation_description"
    ];

    /* 获取项目id，如通过项目列表进去则不需要 */
    async getProjectId() {
        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        let result = await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        }).then((res: any) => {
            let pid = res.data.project_id;
            this.pid = res.data.project_id;
            return pid;
        });
        return result;
    }

    getData(pid: any) {
        this.$axios({
            url: `http://ittn_data.ittn.com.cn/api/project/${pid}/show/1`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: sessionStorage.getItem("lang")
            }
        }).then((res: any) => {
            this.project = res.data.data;
            this.setData(this.project);
            // console.log(res.data.data);
        });
    }

    // 设置info的名字，传入key，返回对应的中文名
    setKeyName(index: any) {
        switch (index) {
            case "id":
                return `${this.$t("project.project")} ID`;
                break;
            case "technology_name":
                return `${this.$t("sign.tech_name_label")}`;
                break;
            case "country_name":
                return `${this.$t("sign.country_label")}`;
                break;
            case "technology_filed_name":
                return `${this.$t("sign.tech_field_label")}`;
                break;
            case "filed_self":
                return `${this.$t("sign.tech_filed_self_label")}`;
                break;
            case "technology_introduction":
                return `${this.$t("sign.tech_intro_label")}`;
                break;
            case "technology_advantage":
                return `${this.$t("sign.tech_advantage")}`;
                break;
            case "technology_stage_name":
                return `${this.$t("sign.tech_stage_label")}`;
                break;
            case "technology_develop":
                return `${this.$t("sign.tech_develop")}`;
                break;
            case "technology_sustainability":
                return `${this.$t("sign.tech_sustain")}`;
                break;
            case "technology_ip_name":
                return `${this.$t("sign.tech_ip")}`;
                break;
            case "ip_owner_name":
                return `${this.$t("sign.tech_ip_owner_label")}`;
                break;
            case "ip_number":
                return `${this.$t("sign.tech_ip_number_label")}`;
                break;
            case "ip_description":
                return `${this.$t("sign.tech_ip_description_label")}`;
                break;
            case "technology_market":
                return `${this.$t("sign.tech_market")}`;
                break;
            case "technology_product_name":
                return `${this.$t("sign.tech_product_label")}`;
                break;
            case "product_description":
                return `${this.$t("sign.tech_product_description_label")}`;
                break;
            case "cooperation_type_name":
                return `${this.$t("sign.cooperation_type_label")}`;
                break;
            case "cooperation_description":
                return `${this.$t("sign.cooperation_description")}`;
                break;
            case "land_china_name":
                return `${this.$t("sign.tech_land_china_label")}`;
                break;
            case "financing":
                return `${this.$t("sign.tech_financing_label")}`;
                break;
            default:
                return index;
                break;
        }
    }

    // 设置需要现实的userdata
    setData(oldData: any) {
        if (oldData.country_id != 0) {
            this.newProject = JSON.parse(JSON.stringify(oldData));

            delete this.newProject.user_id;
            delete this.newProject.country_id;
            delete this.newProject.ip_owner;
            delete this.newProject.land_china;
            delete this.newProject.technology_product;
            delete this.newProject.technology_stage;
            delete this.newProject.technology_ip;
            delete this.newProject.technology_filed;
            delete this.newProject.cooperation_type;
            delete this.newProject.updated_at;
            delete this.newProject.created_at;
        } else {
        }
    }

    mounted() {
        this.getProjectId().then((res: any) => {
            if (res) {
                this.getData(res);
            }
        });
    }
}
