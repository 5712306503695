






















import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class ProjectBasic extends Vue {
    pid: any = "";
    project: object = {};
    newProject: any = {};

    vertical_list: object = ["c_introduction"];

    showInfos: any[] = [
        "id",
        "name",
        "contact",
        "position",
        "phone",
        "email",
        "type_name",
        "basic_info",
        "introduction",
        "advantage",
        "created_at",
    ]; //显示给用户的key
    dateTypeInfos: any[] = ["created_at"]; //需要转换时间格式的key

    /* 获取项目id，如通过项目列表进去则不需要 */
    async getProjectId() {
        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        let result = await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers,
        }).then((res: any) => {
            let pid = res.data.case_id;
            this.pid = res.data.case_id;
            return pid;
        });
        return result;
    }

    getData(pid: any) {
        this.$axios({
            url: `http://ittn_data.ittn.com.cn/api/besttt/case/${pid}`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: sessionStorage.getItem("lang"),
            },
        }).then((res: any) => {
            this.project = res.data.data;
            this.setData(this.project);
            // console.log(res.data.data);
        });
    }

    // 设置info的名字，传入key，返回对应的中文名
    setKeyName(index: any) {
        switch (index) {
            case "id":
                return `案例 ID`;
                break;
            case "name":
                return `案例名称`;
                break;
            case "phone":
                return `联系电话`;
                break;
            case "type_name":
                return `机构类型`;
                break;
            case "contact":
                return `${this.$t("sign.contactLabel")}`;
                break;
            case "basic_info":
                return `基本信息`;
                break;
            case "email":
                return `${this.$t("sign.contact_email_label")}`;
                break;
            case "introduction":
                return `案例背景`;
                break;
            case "advantage":
                return `特征优势`;
                break;

            case "position":
                return `${this.$t("sign.contact_position_label")}`;
                break;

            case "created_at":
                return `${this.$t("scaffold.created_date")}`;
                break;
            default:
                return index;
                break;
        }
    }

    // 设置需要现实的userdata
    setData(oldData: any) {
        if (JSON.stringify(oldData) !== "{}") {
            const transDate = (date: any) => {
                const _date = new Date(date);
                const year = _date.getUTCFullYear();
                const month =
                    _date.getUTCMonth() + 1 < 10
                        ? `0${_date.getUTCMonth() + 1}`
                        : _date.getUTCMonth() + 1;
                const day =
                    _date.getUTCDate() + 1 < 10
                        ? `0${_date.getUTCDate() + 1}`
                        : _date.getUTCDate() + 1;
                const hour =
                    _date.getUTCHours() < 10
                        ? `0${_date.getUTCHours()}`
                        : _date.getUTCHours();
                const minute =
                    _date.getUTCMinutes() < 10
                        ? `0${_date.getUTCMinutes()}`
                        : _date.getUTCMinutes();
                const second =
                    _date.getUTCSeconds() < 10
                        ? `0${_date.getUTCSeconds()}`
                        : _date.getUTCSeconds();
                return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            };

            const add_delay = (object: any, key: any, data: any, i: any) => {
                setTimeout(() => {
                    this.$set(object, key, data);
                }, 50 * i);
            };

            let i: number = 0;
            for (const key in oldData) {
                if (Object.prototype.hasOwnProperty.call(oldData, key)) {
                    let element = oldData[key];
                    if (
                        this.showInfos.findIndex((val: any) => val == key) != -1
                    ) {
                        i++;
                        if (
                            this.dateTypeInfos.findIndex(
                                (val: any) => val == key
                            ) != -1
                        )
                            element = transDate(element);

                        add_delay(this.newProject, key, element, i);
                    }
                }
            }
        }
    }

    mounted() {
        this.getProjectId().then((res: any) => {
            if (res) {
                this.getData(res);
            }
        });
    }
}
