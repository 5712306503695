





































import { Vue, Component, Watch, Provide } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Card } from "element-ui";

@Component({
    components: {
        "el-card": Card,

        addBasicRegistForm(resolve) {
            require(["@/components/sign/BasicRegistForm.vue"], resolve);
        },

        addLoginForm(resolve) {
            require(["@/components/sign/LoginForm.vue"], resolve);
        },

        addProjectBasicForm(resolve) {
            require(["@/components/sign/ProjectBasicForm.vue"], resolve);
        },

        addProjectTechForm(resolve) {
            require(["@/components/sign/ProjectTechForm.vue"], resolve);
        },

        addProjectTeamForm(resolve) {
            require(["@/components/sign/ProjectTeamForm_2.vue"], resolve);
        },

        addProjectFileForm(resolve) {
            require(["@/components/sign/ProjectFileForm.vue"], resolve);
        }
    }
})
export default class Register extends Vue {
    @Getter("getToken") token: any;
    @Action("getSetToken") setToken: any;
    @Action("getSetUserId") setUserId: any;

    channel: object = {}; //渠道信息

    component = {
        /*
            name:异步import组件的方法名,
            id:组件id,
            ref:组件ref,
            childName:子组件ref 调用子组件方法时传参获得子组件,
        */
        currentComponent: "",
        customer_id: "",
        customer_ref: "",
        childName: ""
    };

    stepActived: number = this.$route.params.step
        ? Number(this.$route.params.step)
        : -1; //步骤

    pid: any = this.$route.params.pid ? this.$route.params.pid : undefined; //项目id

    @Provide()
    isdisabled: boolean = false;

    @Provide()
    changeSub(is?: boolean) {
        if (is) {
            this.isdisabled = is;
        } else {
            this.isdisabled == true
                ? (this.isdisabled = false)
                : (this.isdisabled = true);
        }
    }

    /* computed card左上角标题 */
    get signtypeName() {
        if (this.component.currentComponent === "addProjectBasicForm") {
            return this.$t("sign.pStep_2");
        } else if (this.component.currentComponent === "addProjectTechForm") {
            return this.$t("sign.pStep_3");
        } else if (this.component.currentComponent === "addProjectTeamForm") {
            return this.$t("sign.pStep_4");
        } else if (this.component.currentComponent === "addProjectFileForm") {
            return this.$t("sign.pStep_5");
        } else {
            return this.$t("sign.regist");
        }
    }

    /* 提交按钮的文本 */
    get subbtntxt() {
        return this.$t("sign.sub");
    }

    /* 
        加载组件，
        name:异步import组件的方法名,
        id:组件id,
        ref:组件ref,
        childName:子组件ref
    */
    changeComp(name: string, id: string, ref: string, childName: string) {
        this.component.currentComponent = name;
        this.component.customer_id = id;
        this.component.customer_ref = ref;
        this.component.childName = childName;
    }

    /* 提交 */
    sub() {
        this.$nextTick(() => {
            let loading: any;
            let isLoading = async () => {
                loading = this.$loading({
                    lock: true,
                    text: "加载中",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                });
            };
            let closeLoading = async () => {
                loading.close();
            };

            //调用子组件提交方法
            (this.$refs[this.component.customer_ref] as any).sub(
                this.component.childName,
                (status: any, data: any) => {
                    this.afterSub(status, data, closeLoading);
                },
                isLoading
            );
        });
    }

    /* 提交后 */
    afterSub(status: number, data: any, closeLoading: any) {
        // console.log(data);
        if (status === 0) {
            closeLoading();
            this.back();
        } else {
            closeLoading();
        }
    }

    /* 清空 */
    back() {
        let targetRouterName;
        this.stepActived == 1
            ? (targetRouterName = "projectBasic")
            : this.stepActived == 2
            ? (targetRouterName = "projectTech")
            : this.stepActived == 3
            ? (targetRouterName = "projectTeam")
            : this.stepActived == 4
            ? (targetRouterName = "projectFile")
            : false;
        this.$router.push({ name: targetRouterName });
    }

    /* 响应'test'按钮 */
    test() {
        (this.$refs[this.component.customer_ref] as any).test();
    }

    /* 改变表单 */
    changeForm() {
        if (this.stepActived === 1) {
            this.changeComp(
                "addProjectBasicForm",
                "projectBasicForm",
                "projectBasicForm",
                "projectBasicForm"
            );
        } else if (this.stepActived === 2) {
            this.changeComp(
                "addProjectTechForm",
                "projectTechForm",
                "projectTechForm",
                "projectTechForm"
            );
        } else if (this.stepActived === 3) {
            this.changeComp(
                "addProjectTeamForm",
                "projectTeamForm",
                "projectTeamForm",
                "projectTeamForm"
            );
        } else if (this.stepActived === 4) {
            this.changeComp(
                "addProjectFileForm",
                "projectFileForm",
                "projectFileForm",
                "projectFileForm"
            );
        } else {
            this.changeComp("", "", "", "");
        }
    }

    //添加成员
    addTeamMembers() {
        if (this.component.currentComponent == "addProjectTeamForm") {
            (this.$refs[this.component.customer_ref] as any).addTeamMembers();
        }
    }

    /* 监听路由改变注册步骤 */
    @Watch("$route")
    routeChange(to: any, from: any) {
        document.documentElement.scrollTop = 0; //路由改变后，页面滚动到顶
        this.stepActived = to.params.step ? Number(to.params.step) : 1;
        this.changeForm();
    }

    mounted() {
        /* 组件加载完毕后，加载账号注册表单 */
        this.changeForm();
    }
}
