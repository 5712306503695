


















import { Vue, Component } from "vue-property-decorator";
@Component({})
export default class ProjectFile extends Vue {
    pid: any = "";
    project: object = {};
    newProject: any = {};

    /* 获取项目id，如通过项目列表进去则不需要 */
    async getProjectId() {
        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        let result = await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        }).then((res: any) => {
            let pid = res.data.project_id;
            this.pid = res.data.project_id;
            return pid;
        });
        return result;
    }

    getData(pid: any) {
        this.$axios({
            url: `http://ittn_data.ittn.com.cn/api/projectfile/${pid}/index`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: sessionStorage.getItem("lang")
            }
        }).then((res: any) => {
            this.project = res.data;
            this.setData(this.project);
        });
    }

    // 设置需要现实的userdata
    setData(oldData: any) {
        this.newProject = JSON.parse(JSON.stringify(oldData));
    }

    mounted() {
        this.getProjectId().then((res: any) => {
            this.getData(res);
        });
    }
}
