




































import { Vue, Component } from "vue-property-decorator";
@Component({})
export default class ProjectTeam extends Vue {
    pid: any = "";
    project: object = {};
    newProject: any = {};

    vertical_list = ["headimg", "introduction"];

    /* 获取项目id，如通过项目列表进去则不需要 */
    async getProjectId() {
        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        let result = await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        }).then((res: any) => {
            let pid = res.data.project_id;
            this.pid = res.data.project_id;
            return pid;
        });
        return result;
    }

    /**
     * 请求团队成员数据
     */
    getData(pid: any) {
        this.$axios({
            url: `http://ittn_data.ittn.com.cn/api/projectteam/${pid}/index`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: sessionStorage.getItem("lang")
            }
        }).then((res: any) => {
            this.project = res.data;
            this.setData(this.project);
            // console.log(res.data.data);
        });
    }

    // 设置info的名字，传入key，返回对应的中文名
    setKeyName(index: any) {
        switch (index) {
            case "id":
                return `${this.$t("sign.memeber")} ID`;
                break;
            case "is_leader":
                return `${this.$t("sign.is_leader")}`;
                break;
            case "name":
                return `${this.$t("sign.team_name_label")}`;
                break;
            case "headimg":
                return `${this.$t("sign.team_headimg")}`;
                break;
            case "postion":
                return `${this.$t("sign.team_position_label")}`;
                break;
            case "degree":
                return `${this.$t("sign.team_degree_label")}`;
                break;
            case "graduate":
                return `${this.$t("sign.team_graduate_label")}`;
                break;
            case "birthday":
                return `${this.$t("sign.team_birthday")}`;
                break;
            case "country_name":
                return `${this.$t("sign.team_country_id_label")}`;
                break;
            case "contact":
                return `${this.$t("sign.team_contact_label")}`;
                break;
            case "field":
                return `${this.$t("sign.team_field_label")}`;
                break;
            case "introduction":
                return `${this.$t("sign.team_intro_label")}`;
                break;
            default:
                return index;
                break;
        }
    }

    setContent(index: any, item: any) {
        switch (index) {
            case "headimg":
                return item
                    ? `<img src="${item}" alt="item">`
                    : this.$t("scaffold.noData");
                break;
            case "is_leader":
                return item == 1
                    ? this.$t("sign.team_leader")
                    : this.$t("sign.team_member");
            case "birthday":
                if (item) {
                    const _date = new Date(item);
                    const year = _date.getUTCFullYear();
                    const month = _date.getUTCMonth() + 1;
                    const date = _date.getUTCDate() + 1;
                    return `${year}-${month}-${date}`;
                } else {
                    return this.$t("scaffold.noData");
                }
            default:
                return item ? item : this.$t("scaffold.noData");
                break;
        }
        // if (index == "headimg") {
        //     return item
        //         ? `<div class="pic"><img src="${item}" alt="item"></div>`
        //         : "暂无数据";
        // } else {
        //     return item ? item : "暂无数据";
        // }
    }

    // 设置需要现实的userdata
    setData(oldData: any) {
        this.newProject = JSON.parse(JSON.stringify(oldData.data));
        console.log(this.newProject);

        for (const key in this.newProject) {
            if (this.newProject.hasOwnProperty(key)) {
                const el = this.newProject[key];
                delete el.projects_id;
                delete el.created_at;
                delete el.updated_at;
                delete el.country_id;
                // console.log(el);
            }
        }

        // delete this.newProject.user_id;
        // delete this.newProject.country_id;
        // delete this.newProject.ip_owner;
        // delete this.newProject.land_china;
        // delete this.newProject.technology_product;
        // delete this.newProject.technology_stage;
        // delete this.newProject.technology_ip;
        // delete this.newProject.technology_filed;
        // delete this.newProject.cooperation_type;
        // delete this.newProject.updated_at;
        // delete this.newProject.created_at;
    }

    mounted() {
        this.getProjectId().then((res: any) => {
            this.getData(res);
        });
    }
}
