

























import { Vue, Component, Watch, Provide } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Card } from "element-ui";

@Component({
    components: {
        "el-card": Card,

        addExpertBasicForm(resolve) {
            require(["@/components/sign/ExpertBasicForm.vue"], resolve);
        }
    }
})
export default class Register extends Vue {
    @Getter("getToken") token: any;
    @Action("getSetToken") setToken: any;
    @Action("getSetUserId") setUserId: any;

    channel: object = {}; //渠道信息

    component = {
        /*
            name:异步import组件的方法名,
            id:组件id,
            ref:组件ref,
            childName:子组件ref 调用子组件方法时传参获得子组件,
        */
        currentComponent: "",
        customer_id: "",
        customer_ref: "",
        childName: ""
    };

    stepActived: number = this.$route.params.step
        ? Number(this.$route.params.step)
        : -1; //步骤

    eid: any = this.$route.params.eid ? this.$route.params.eid : undefined; //项目id

    /* computed card左上角标题 */
    get signtypeName() {
        if (this.component.currentComponent === "addBasicRegistForm") {
            return this.$t("sign.regist");
        } else if (this.component.currentComponent === "addLoginForm") {
            return this.$t("sign.login");
        } else if (this.component.currentComponent === "addProjectBasicForm") {
            return this.$t("sign.pStep_2");
        } else {
            return this.$t("sign.regist");
        }
    }

    /* 提交按钮的文本 */
    get subbtntxt() {
        return this.$t("sign.sub");
    }

    /* 
        加载组件，
        name:异步import组件的方法名,
        id:组件id,
        ref:组件ref,
        childName:子组件ref
    */
    changeComp(name: string, id: string, ref: string, childName: string) {
        this.component.currentComponent = name;
        this.component.customer_id = id;
        this.component.customer_ref = ref;
        this.component.childName = childName;
    }

    /* 提交 */
    sub() {
        this.$nextTick(() => {
            let loading: any;
            let isLoading = async () => {
                loading = this.$loading({
                    lock: true,
                    text: "加载中",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                });
            };
            let closeLoading = async () => {
                loading.close();
            };

            //调用子组件提交方法
            (this.$refs[this.component.customer_ref] as any).sub(
                this.component.childName,
                (status: any, data: any) => {
                    this.afterSub(status, data, closeLoading);
                },
                isLoading
            );
        });
    }

    /* 提交后 */
    afterSub(status: number, data: any, closeLoading: any) {
        // console.log(data);
        if (status === 0) {
            closeLoading();
            this.back();
        } else {
            closeLoading();
        }
    }

    /* 返回 */
    back() {
        let targetRouterName;
        this.stepActived == 1 ? (targetRouterName = "expertBasic") : false;
        this.$router.push({ name: targetRouterName });
    }

    /* 响应'test'按钮 */
    test() {
        (this.$refs[this.component.customer_ref] as any).test();
    }

    /* 改变表单 */
    changeForm() {
        if (this.stepActived === 1) {
            this.changeComp(
                "addExpertBasicForm",
                "expertBasicForm",
                "expertBasicForm",
                "expertBasicForm"
            );
        } else {
            this.changeComp("", "", "", "");
        }
    }

    /* 监听路由改变注册步骤 */
    @Watch("$route")
    routeChange(to: any, from: any) {
        document.documentElement.scrollTop = 0; //路由改变后，页面滚动到顶
        this.stepActived = to.params.step ? Number(to.params.step) : 1;
        this.changeForm();
    }

    mounted() {
        /* 组件加载完毕后，加载账号注册表单 */
        this.changeForm();
    }
}
