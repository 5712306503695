

























import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class ProjectBasic extends Vue {
    eid: any = "";
    project: object = {};
    newProject: any = {};

    vertical_list: object = ["brief", "avatar"];

    /* 获取项目id，如通过项目列表进去则不需要 */
    async getProjectId() {
        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        let result = await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        }).then((res: any) => {
            let eid = res.data.expert_id;
            this.eid = res.data.expert_id;
            // console.log(res.data);

            return eid;
        });
        return result;
    }

    getData(eid: any) {
        // console.log(eid);

        this.$axios({
            url: `http://ittn_data.ittn.com.cn/api/expert/${eid}/show`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: sessionStorage.getItem("lang")
            }
        }).then((res: any) => {
            this.project = res.data.data;
            this.setData(this.project);
            // console.log(res.data.data);
        });
    }

    // 设置info的名字，传入key，返回对应的中文名
    setKeyName(index: any, item: any) {
        switch (index) {
            case "id":
                return `${this.$t("expert.expert")} ID`;
                break;
            case "name":
                return `${this.$t("sign.expert_name_label")}`;
                break;
            case "country_name":
                return `${this.$t("sign.expert_country_label")}`;
                break;
            case "avatar":
                return `${this.$t("sign.expert_avatar")}`;
                break;
            case "birthday":
                return `${this.$t("sign.expert_birthday_label")}`;
                break;
            case "email":
                return `${this.$t("sign.contact_email_label")}`;
                break;
            case "phone":
                return `${this.$t("sign.expert_phone_label")}`;
                break;
            case "wechat":
                return `${this.$t("sign.expert_wechat_label")}`;
                break;
            case "graduate":
                return `${this.$t("sign.expert_graduate_label")}`;
                break;
            case "degree":
                return `${this.$t("sign.expert_degree_label")}`;
                break;
            case "technical_field_name":
                return `${this.$t("sign.expert_technical_field_label")}`;
                break;
            case "company":
                return `${this.$t("sign.expert_company_label")}`;
                break;
            case "position":
                return `${this.$t("sign.expert_position_label")}`;
                break;
            case "brief":
                return `${this.$t("sign.expert_brief_label")}`;
                break;
            case "executive_position":
                return `${this.$t("sign.expert_executive_position_label")}`;
                break;
            case "professional_title":
                return `${this.$t("sign.expert_professional_title_label")}`;
                break;
            case "faculty_position":
                return `${this.$t("sign.expert_faculty_position_label")}`;
                break;
            case "professional_affiliation":
                return `${this.$t(
                    "sign.expert_professional_affiliation_label"
                )}`;
                break;
            case "honor":
                return `${this.$t("sign.expert_honor_label")}`;
                break;
            default:
                return index;
                break;
        }
    }

    setContent(index: any, item: any) {
        switch (index) {
            case "avatar":
                return item
                    ? `<img src="${item}" alt="item" style="max-width:300px;height:auto">`
                    : this.$t("scaffold.noData");
                break;
            case "birthday":
                if (item) {
                    const _date = new Date(item);
                    const year = _date.getUTCFullYear();
                    const month =
                        _date.getUTCMonth() + 1 < 10
                            ? `0${_date.getUTCMonth() + 1}`
                            : _date.getUTCMonth() + 1;
                    const day =
                        _date.getUTCDate() + 1 < 10
                            ? `0${_date.getUTCDate() + 1}`
                            : _date.getUTCDate() + 1;
                    return `${year}-${month}-${day}`;
                } else {
                    return this.$t("scaffold.noData");
                }
                break;
            default:
                return item ? item : this.$t("scaffold.noData");
                break;
        }
    }

    // 设置需要显示的userdata
    setData(oldData: any) {
        this.newProject = JSON.parse(JSON.stringify(oldData));
        const social_title = JSON.parse(this.newProject.social_title);
        const social_title_keys = Object.keys(social_title);
        for (const i of social_title_keys) {
            this.newProject[i] = social_title[i];
        }

        delete this.newProject.country_id;
        delete this.newProject.technical_field;
        delete this.newProject.expert_cn;
        delete this.newProject.expert_en;
        delete this.newProject.social_title;

        delete this.newProject.user_id;
        delete this.newProject.updated_at;
        delete this.newProject.created_at;
    }

    mounted() {
        this.getProjectId().then((res: any) => {
            this.getData(res);
        });
    }
}
